import React from 'react';
import styled from 'styled-components';
import Media from 'react-media';

import Separator from './Separator';
import { Text } from './Texts';

const RelativeText = styled.div`
    position: relative;
    top: 13px;
    @media only screen and (max-width: 599px) {
        top: 14px !important;
    }
`;

const InlineDiv = styled.div`
    display:inline-block;
`;

const StrokedTextSmall = ({children, strokeColor, strokeWidth, strokeMargin, strokeMarginMobile, className, style}) => (
    <InlineDiv
        className={className}
        style={{...{
            // margin: '0 20px',
        }, ...style}}
    >
        <RelativeText>
            <Text
                style={{
                    backgroundColor: 'transparent',
                    whiteSpace: 'nowrap',
                    fontSize: '1rem',
                    fontWeight: 600,
                }}
                css={`
                    @media only screen and (max-width: 599px) {
                        font-size: 0.875rem;
                    }
                `}
            >
                {children}
            </Text>
        </RelativeText>
        <Separator
            strokeColor={strokeColor}
            strokeWidth={strokeWidth || "1.1rem"}
            strokeMarginMobile={strokeMarginMobile}
            style={{margin: strokeMargin || '0 -10px'}}
            css={`
                @media only screen and (max-width: 599px) {
                    margin: ${props => props.strokeMarginMobile || props.strokeMargin ||  '0 -10px'} !important;
                }
            `}
        />
    </InlineDiv>
);

const StrokedTextDesktop = ({children, strokeColor, strokeWidth, strokeMargin, className, style}) => (
    <InlineDiv
        style={{...{
            // margin: '0 20px',
        }, ...style}}
        className={className}
    >
        <RelativeText>
            <Text
                style={{
                    backgroundColor: 'transparent',
                    // whiteSpace: 'nowrap',
                    fontSize: '1rem',
                    fontWeight: 600,
                }}
            >
                {children}
            </Text>
        </RelativeText>
        <Separator
            strokeColor={strokeColor}
            strokeWidth={strokeWidth || "2.2rem"}
            style={{margin: strokeMargin || '0 -10px'}}
        />
    </InlineDiv>
);

const StrokedText = (props) => (
    <Media query="(max-width: 599px)">
        {matches =>
            matches ? (
                <StrokedTextSmall {...props} />
            ) : (
                <StrokedTextDesktop {...props} />
            )
        }
    </Media>
);

export default StrokedText;
