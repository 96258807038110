import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image'
import Image from 'gatsby-image';
import withTheme from '../../theme';

import Section from '../../components1/Section';
import { Text, Title } from '../../components1/Texts';
import FlexDiv from '../../components1/FlexDiv';
import LinedTitle from '../../components1/LinedTitle';
import { CabadiciButton } from '../../components1/buttons';
import ParentheseBackgroundCarre from '../../components1/ParentheseBackgroundCarre';

export const livraisonData = [
    {
        text: 'Point de retrait',
        price: ' 0',
        text2: 'Chez un de nos partenaires'
    },
    {
        text: 'Livraison groupée',
        price: ' 0',
        text2: 'À partir de 2 commandes à livrer dans la même zone'
    },
    {
        text: 'Livraison privée',
        price: '10',
        text2: 'Où vous voulez'
    },
];

const Section5 = ({ theme, isIndex, children, openConnectForm }) => {
    const data = useStaticQuery(graphql`
        query {
            backgroundParenthesesNoires: file(relativePath: { eq: "backgrounds/parentheses-noires-carre.png" }) {
                childImageSharp {
                    fluid(maxHeight: 100, quality: 90) {
                        ...GatsbyImageSharpFluid_noBase64
                    }
                }
            }
            footer: file(relativePath: { eq: "index/footer.jpg" }) {
                ...fullWidthImage
            }
        }
    `);
    return (
        <>
            <FlexDiv
                style={{
                    backgroundColor: theme.color.jauneFort,
                    justifyContent: 'center'
                }}
                id="section5"
            >
                <Title
                    style={{
                        padding: '10px 0',
                    }}
                    css={`
                        @media only screen and (max-width: 599px) {
                            font-size: 1.5rem !important;
                        }
                    `}
                >
                    Les Livraisons
                </Title>
            </FlexDiv>
            <FlexDiv
                style={{
                    backgroundColor: theme.color.beigeFaible,
                    flexDirection: 'column',
                    alignItems: 'stretch'
                }}
            >
                <Text
                    style={{
                        fontFamily: theme.font.title,
                        fontSize: '1.15rem',
                        textAlign: 'center',
                        padding: '10px',
                    }}
                    css={`
                        @media only screen and (max-width: 599px) {
                            font-size: 1rem !important;
                        }
                    `}
                >
                    {
                        isIndex ? (
                            <>
                                Profitez de votre CABADICI en fin de semaine
                                <br/>
                                grâce à nos livraisons le vendredi
                            </>
                        ) :(
                        <>
                            Toutes les livraisons ont lieu les vendredis
                            <br/>
                            pour vous permettre de découvrir votre CABADICI
                            <br/>
                            en fin de semaine.
                        </>)
                    }
                </Text>
                <LinedTitle
                    style={{
                        fontFamily: theme.font.main,
                        fontSize: '1rem',
                        // fontWeight: 'bold',
                        textTransform: 'none',
                        padding: 0,
                    }}
                    borderColor={theme.color.jauneFaible}
                    borderWidth="2px"
                    css={`
                        @media only screen and (max-width: 599px) {
                            font-size: 0.875rem !important;
                            grid-gap: 5px !important;
                        }
                    `}
                >
                    {
                        isIndex ? '3 SOLUTIONS POSSIBLES :' : (
                            <Text
                                style={{
                                    textTransform: 'uppercase',
                                    paddingLeft: '1rem',
                                    paddingRight: '1rem',
                                }}
                            >Choisissez votre livraison</Text>)
                    }
                </LinedTitle>
                {
                    children || (
                        <>
                            <FlexDiv style={{
                                // flexDirection: 'column',
                                flex: 1,
                                // maxWidth: 700,
                                justifyContent: 'center',
                                // alignItems: 'stretch'
                                paddingTop: 40,
                            }}>
                                <FlexDiv
                                    style={{
                                        justifyContent: 'space-around',
                                        flexWrap: 'wrap',
                                    }}
                                >
                                    {
                                        livraisonData.map((livraison, index) => (
                                            <FlexDiv
                                                key={index.toString()}
                                                style={{
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    paddingBottom: 10,
                                                    paddingTop: 10,
                                                }}
                                            >
                                                <Text
                                                    style={{
                                                        textTransform: 'uppercase',
                                                        // fontWeight: 900,
                                                        paddingBottom: 15,
                                                        justifySelf: 'center',
                                                        fontSize: '0.8rem',
                                                        whiteSpace: 'nowrap',
                                                    }}
                                                >
                                                    {livraison.text}
                                                </Text>
                                                <ParentheseBackgroundCarre>
                                                    <Title
                                                        style={{
                                                            fontSize: '4rem',
                                                            fontWeight: 100,
                                                            // whiteSpace: 'pre'
                                                        }}
                                                        css={`
                                                            @media only screen and (max-width: 599px) {
                                                                font-size: 3rem !important;
                                                            }
                                                        `}
                                                    >
                                                        {`${livraison.price}€`}
                                                    </Title>
                                                </ParentheseBackgroundCarre>
                                                <Text
                                                    style={{
                                                        fontSize: '0.875rem',
                                                        textAlign: index === 1 ? 'justify' : 'center',
                                                        // textAlign: 'justify',
                                                        textAlignLast: 'center',
                                                        // width: 150,
                                                        padding: '20px 10px 0 10px'
                                                    }}
                                                    css={`
                                                        @media only screen and (max-width: 599px) {
                                                            font-size: 0.625rem !important;
                                                        }
                                                    `}
                                                >
                                                    {livraison.text2}
                                                </Text>
                                            </FlexDiv>
                                        ))
                                    }
                                </FlexDiv>

                            </FlexDiv>
                            <Section>
                                <Text
                                    style={{
                                        fontFamily: theme.font.title,
                                        fontSize: 18,
                                        textAlign: 'center',
                                        textAlignLast: 'center',
                                        paddingTop: 20,
                                        paddingBottom: 40,
                                    }}
                                    css={`
                                        @media only screen and (max-width: 599px) {
                                            font-size: 0.85rem !important;
                                        }
                                    `}
                                >
                                    {'N\'hésitez pas à vous faire livrer sur votre lieu de travail !'}
                                    <br />
                                    Grâce à son emballage isotherme,
                                    <br />
                                    votre CABADICI vous attendra
                                    <br />
                                    même si vous n’avez pas de réfrigérateur sous la main.
                                </Text>
                            </Section>
                            <FlexDiv style={{justifyContent: 'center'}}>
                                <CabadiciButton
                                    color="vertFort"
                                    onClick={() => openConnectForm()}
                                >
                                    commander
                                </CabadiciButton>
                            </FlexDiv>
                            <Image
                                fluid={data.footer.childImageSharp.fluid}
                                alt="header"
                                css={`
                                    @media only screen and (min-width: 599px) {
                                        /*display: none;*/
                                    }
                                `}
                            />
                    </>)
                }
            </FlexDiv>
        </>
    );
};

Section5.defaultProps = {
    isIndex: false
};

Section5.propTypes = {
    isIndex: PropTypes.bool,
    theme: PropTypes.shape().isRequired
};

export default withTheme(Section5);
