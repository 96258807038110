import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image'

const ParentheseBackground = ({ children, className, size }) => {
    const data = useStaticQuery(graphql`
        query {
            backgroundParenthesesNoiresCarreFluid: file(relativePath: { eq: "backgrounds/parentheses-noires-carre.png" }) {
                childImageSharp {
                    fluid(maxWidth: 200, quality: 90) {
                        ...GatsbyImageSharpFluid_noBase64
                        presentationWidth
                    }
                }
            }
        }
    `);

    return (
        <BackgroundImage
            fluid={data.backgroundParenthesesNoiresCarreFluid.childImageSharp.fluid}
            style={{
                backgroundSize: 'contain',

                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minWidth: size.desktop,
                minHeight: size.desktop,
                margin: '0 auto',
            }}
            loading="eager"
            fadeIn={false}
            className={className}
            css={`
                @media only screen and (max-width: 599px) {
                    min-width: ${size.mobile} !important;
                    min-height: ${size.mobile} !important;
                }
            `}
        >
            {children}
        </BackgroundImage>
    );
};

ParentheseBackground.defaultProps = {
    size: {
        desktop: '10rem',
        mobile: '7rem',
    },
};

ParentheseBackground.propTypes = {
    children: PropTypes.element.isRequired,
    className: PropTypes.shape(),
    size: PropTypes.shape(),
};

export default ParentheseBackground;

/*

fluid={data.backgroundParenthesesNoires.childImageSharp.fluid}
style={{
    // default values:
    // backgroundRepeat: 'no-repeat',
    // backgroundSize: 'cover',
    // backgroundPosition: 'center',

    backgroundSize: '100% 100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    minWidth: '10rem',
    minHeight: '10rem',
}}
fadeIn={false}
css={`
    @media only screen and (max-width: 599px) {
        min-width: 7rem !important;
        min-height: 7rem !important;
    }
`}

*/

/* ici-ou
fluid={data.backgroundParenthesesNoires.childImageSharp.fluid}
style={{
    // default values:
    // backgroundRepeat: 'no-repeat',
    // backgroundSize: 'cover',
    // backgroundPosition: 'center',

    backgroundSize: '100% 100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
}}
fadeIn={false}
css={`
    @media only screen and (max-width: 599px) {
        min-height: 135px !important;
        margin-top: 5px !important;
    }
`}
*/

/* livraisons
fluid={data.backgroundParenthesesNoires.childImageSharp.fluid}
style={{
    // default values:
    // backgroundRepeat: 'no-repeat',
    // backgroundSize: 'cover',
    // backgroundPosition: 'center',

    backgroundSize: '100% 100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    minWidth: '7rem',
    minHeight: '7rem',
}}
fadeIn={false}
css={`
    @media only screen and (max-width: 599px) {
        min-width: 4rem !important;
        min-height: 4rem !important;
    }
`}
*/
