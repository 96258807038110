import React, { Component } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Media from 'react-media';
import BackgroundImage from 'gatsby-background-image'

import withTheme from '../theme';

import { Layout } from '../components1/layout';
import { Text, Bold } from '../components1/Texts';
import FlexDiv from '../components1/FlexDiv';
import Section from '../components1/Section';
import { CabadiciButton } from '../components1/buttons';
import PartnerModal from '../containers/modals/PartnerDeliveryAddressModal';

import Subtitle from '../components1/Subtitle2';
import StrokedText from '../components1/StrokedText';
import ParentheseBackgroundCarre from '../components1/ParentheseBackgroundCarre';

import SectionLivraison, {
    livraisonData,
} from '../containers/sections/5-livraisons';

export const HR = withTheme(styled.div`
    border: none;
    border-left: ${props => (props.strokeWeight ? props.strokeWeight : '1px')}
        solid ${props => props.theme.color[props.strokeColor]};
    height: 100%;
    min-height: 80px;
    width: 1px;
`);

const GridContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    /*grid-template-rows: 200px auto 80px;*/
    grid-template-rows: repeat(2, 150px);
    /* for all rows after 2nd: */
    grid-auto-rows: auto;
    grid-column-gap: 10px;
    grid-row-gap: 5px;
    margin-top: 10px;
    margin-bottom: 30px;
    grid-template-areas:
        'a b c'
        'a b c'
        /*'a b c'*/
        'd d d'
        '. e f'
        'g g g'
        '. . h'
        'i i i'
        'j j j';

    @media only screen and (max-width: 599px) {
        grid-column-gap: 0px;
        grid-row-gap: 0px;

    }
`;

const TextResponsive = styled(Text)`
    @media only screen and (max-width: 599px) {
        font-size: 12px;
        padding-left: 0px;
        padding-right: 0px;
    }
`;

const strokeColors = ['vertFort', 'jauneFort', 'rougeFaible'];

class LivraisonPage extends Component {

    modal = React.createRef();

    openModal = () => {
        this.modal.current.openModal();
    };

    render() {
        const { data } = this.props;
        return (
            <Layout>
                {
                    ({ openConnectForm }) => (
                        <>
                            <SectionLivraison>
                                <Section
                                    style={{
                                        marginTop: 10,
                                        marginLeft: 0,
                                        marginRight: 0,
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}>
                                    <div
                                        style={{
                                            maxWidth: 700,
                                            alignItems: 'stretch',
                                        }}>
                                        <GridContainer
                                            style={
                                                {
                                                    // maxWidth: 700,
                                                    // alignItems: 'stretch'
                                                }
                                            }>
                                            {livraisonData.map((livraison, index) => (
                                                <FlexDiv
                                                    key={index.toString()}
                                                    style={{
                                                        gridArea: ['a', 'b', 'c'][index],
                                                        flexDirection: 'column',
                                                        alignItems: 'center',
                                                    }}>
                                                    <Text
                                                        style={{
                                                            textTransform: 'uppercase',
                                                            fontWeight: 600,
                                                            paddingBottom: 30,
                                                            justifySelf: 'center',
                                                            whiteSpace: 'nowrap'
                                                        }}
                                                        css={`
                                                            @media only screen and (max-width: 599px) {
                                                                font-size: 0.875rem !important;
                                                                white-space: normal !important;
                                                                padding: 0 0 30px 0 !important;
                                                                text-align: center;
                                                            }
                                                        `}
                                                    >
                                                        {livraison.text}
                                                    </Text>
                                                    <ParentheseBackgroundCarre
                                                        size={{
                                                            desktop: '7rem',
                                                            mobile: '4rem',
                                                        }}
                                                    >
                                                        <Text
                                                            style={{
                                                                fontSize: '3.5rem',
                                                                fontWeight: 100,
                                                            }}
                                                            css={`
                                                                @media only screen and (max-width: 599px) {
                                                                    font-size: 2rem !important;
                                                                }
                                                            `}
                                                        >
                                                            {`${livraison.price}€`}
                                                        </Text>
                                                    </ParentheseBackgroundCarre>
                                                    <HR
                                                        strokeColor={strokeColors[index]}
                                                        strokeWeight="2px"
                                                    />
                                                </FlexDiv>
                                            ))}
                                            <FlexDiv
                                                style={{
                                                    gridArea: 'd',
                                                    flexDirection: 'column',
                                                }}>
                                                <StrokedText
                                                    style={{
                                                        alignSelf: 'flex-start',
                                                        paddingBottom: 10,
                                                    }}
                                                    strokeColor="vertFaible"
                                                    strokeWidth="0.6rem"
                                                    strokeMargin="0 -40px 0 0"
                                                    strokeMarginMobile="0 -10px 0 0"
                                                >
                                                    Le retrait chez un de nos partenaires
                                                </StrokedText>
                                                <Text>
                                                    1. Choisissez votre créneau de livraison
                                                    <br />
                                                    {'2. '}
                                                    <span
                                                        role="button"
                                                        style={{
                                                            textDecoration: 'underline',
                                                        }}
                                                        css={`
                                                            :hover{
                                                                cursor: pointer;
                                                            }
                                                        `}
                                                        onClick={this.openModal}
                                                    >
                                                        Sélectionner notre partenaire le plus proche
                                                        de chez vous
                                                    </span>
                                                    <br />
                                                    3. Vous êtes prévenus pas SMS dès que votre
                                                    CABADICI y est déposé
                                                    <br />
                                                    4. Récupérez votre CABADICI quand cela vous
                                                    arrange !
                                                </Text>
                                            </FlexDiv>
                                            <HR
                                                style={{
                                                    gridArea: 'e',
                                                    justifySelf: 'center',
                                                }}
                                                strokeColor="jauneFaible"
                                                strokeWeight="2px"
                                            />
                                            <HR
                                                style={{
                                                    gridArea: 'f',
                                                    justifySelf: 'center',
                                                }}
                                                strokeColor="rougeFaible"
                                                strokeWeight="2px"
                                            />
                                            <FlexDiv
                                                style={{
                                                    gridArea: 'g',
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                }}>
                                                <StrokedText
                                                    style={{
                                                        alignSelf: 'center',
                                                        paddingBottom: 10,
                                                    }}
                                                    strokeColor="jauneFaible"
                                                    strokeWidth="0.6rem"
                                                    strokeMargin="0 -80px"
                                                    strokeMarginMobile="0 -10px"
                                                >
                                                    La livraison groupée
                                                </StrokedText>
                                                <Text
                                                    style={{
                                                        textAlign: 'center',
                                                    }}>
                                                    <Bold>Disponible très prochainement</Bold>
                                                    <br />
                                                    Le regroupement de livraisons permettra de
                                                    limiter notre impact sur
                                                    <br />
                                                    {
                                                        "l'environnement mais aussi de vous éviter les frais d'une livraison particulière."
                                                    }
                                                    <br />
                                                    {
                                                        "Dès aujourd'hui, n'hésitez pas à nous envoyer un mail si vous souhaitez nous"
                                                    }
                                                    <br />
                                                    indiquer un co-livré de votre connaissance !
                                                </Text>
                                            </FlexDiv>
                                            <HR
                                                style={{
                                                    gridArea: 'h',
                                                    justifySelf: 'center',
                                                }}
                                                strokeColor="rougeFaible"
                                                strokeWeight="2px"
                                            />
                                            <FlexDiv
                                                style={{
                                                    gridArea: 'i',
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                }}>
                                                <StrokedText
                                                    style={{
                                                        alignSelf: 'flex-end',
                                                        paddingBottom: 10,
                                                    }}
                                                    strokeColor="rougeFaible"
                                                    strokeWidth="0.6rem"
                                                    strokeMargin="0 0 0 -80px"
                                                    strokeMarginMobile="0 0 0 -20px"
                                                >
                                                    La livraison personnelle
                                                </StrokedText>
                                                <Text
                                                    style={{
                                                        textAlign: 'right',
                                                    }}>
                                                    1. Choisissez votre créneau de livraison
                                                    <br />
                                                    {"2. Entrez l'adresse de votre choix"}
                                                    <br />
                                                    {
                                                        "3. Vous êtes prévenus pas SMS pour vous préciser l'horaire."
                                                    }
                                                </Text>
                                            </FlexDiv>
                                            <Text
                                                style={{
                                                    gridArea: 'j',
                                                    textAlign: 'right',
                                                    fontSize: 12,
                                                }}>
                                                Les livraisons privées pourront être aussi bien
                                                assurées par nos soins
                                                <br />
                                                ou par notre transporteur agréé, CHRONOFRESH.
                                                <br />
                                                Grâce à ce spécialiste du transport de produits
                                                frais, les livraisons sont possibles partout en
                                                <br />
                                                France métropolitaine, pour les cuisiniers en
                                                vacances ou loin DICI !<br />
                                                Dans le cas où votre livraison serait prise en
                                                charge par Chronofresh, nous ne pourrons vous
                                                <br />
                                                préciser davantage le moment de votre livraison.
                                            </Text>
                                        </GridContainer>
                                    </div>
                                </Section>
                                <FlexDiv style={{ justifyContent: 'center', paddingTop: 30 }}>
                                    <CabadiciButton
                                        color="rougeFort"
                                        style={{width: 170}}
                                        onClick={openConnectForm}
                                    >
                                        commander
                                    </CabadiciButton>
                                </FlexDiv>
                            </SectionLivraison>
                            <PartnerModal ref={this.modal} isSelectable={false} />
                        </>
                    )
                }
            </Layout>
        );
    }
}

export default LivraisonPage;
